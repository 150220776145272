import { MDXProvider } from "@mdx-js/react";
import React from "react";
import Layout from "./src/components/layout";

const BlogParagraph = props => (
    <p className="my-5 py-1" {...props} />
)

const BlogList = props => (
    <ul className="list-disc px-20" {...props} />
)

const components = {
    p: BlogParagraph,
    ul: BlogList

}

export const rootWrapper = ({ element }) => {
    return (
            <Layout>
                    <div className="container mx-auto"></div>
                    <MDXProvider components={components}>{element}</MDXProvider>
            </Layout>
    );
}