/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'fontsource-noto-sans';
import './src/styles/global.css';
import { rootWrapper } from './root-wrapper';

export const wrapPageElement = rootWrapper;