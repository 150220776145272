import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Header = ({ siteTitle }) => (
  <header
    className="bg-black block h-16"
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <ul
        className="list-none"
      >
        <li className="header-menu-item">
            <Link
              to="/"
            >
              {siteTitle}
            </Link>
        </li>
        <li className="header-menu-item">
          <a
            href="https://logickal.bandcamp.com"
          >Bandcamp</a>
          </li>
        <li className="header-menu-item">
        <a
          href="https://soundcloud.com/logickal"
        >SoundCloud</a>
        </li>
        <li className="header-menu-item">
          <a
            href="https://www.youtube.com/channel/UCe5U0fdl9vWC7ZkRvZunhvA"
          >YouTube</a>
        </li>
        <li className="header-menu-item">
          <a
            href="https://twitch.tv/logickal_musick"
          >Twitch</a>
        </li>
        <li className="header-menu-item">
          <a
          href="https://esoteric.party/@logickal"
          >Fediverse</a>
        </li>
        <li>
          <Link
            to="/blog"
            >Blog</Link>
        </li>
      </ul>
      
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
